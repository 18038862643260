import { fb } from '../../../lib/form';
export function getCategoryListModel(data) { 
    var options = [{name : 'All', value : ''}, {name : 'Favourites', value : '_favourites_'}];
    if(data.length > 0){
      data.forEach(el => {
        options.push({name : el.categoryName, value : el.categoryId});
      });
    }
    const model = fb.group({
      categoryList: [data.categoryList || '', [], {
        label: 'FILTER BY',
        type: 'select',
        options : options
     }]
    }); 
    return model;
}