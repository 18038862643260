/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState } from "react";
import utils from "utils";
import cmsUtils from "../../cmsUtils";
import style from "./timerStyle";

export function Timer(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "timer__item",
    "timer__item2",
    item.cssClass || item.anchorName || ""
  );
  const timer = cmsUtils.payload(item, "Timer");
  //const timer = "2023-04-12T16:00:00Z"; // Ensure this format is used: ISO 8601 with UTC

  const [timerStatus, setTimerStatus] = useState("");

  useEffect(() => {
    const existingScript = document.getElementById("flipdowntimer");
    const existingLink = document.getElementById("flipdowntimerstyle");
    if (!existingScript) {
      const script = document.createElement("script");
      script.id = "flipdowntimer";
      script.type = `text/javascript`;
      script.src = "/dist/flipdown.min.js";
      document.body.appendChild(script);
    }

    if (!existingLink) {
      const link = document.createElement("link");
      link.id = "flipdowntimerstyle";
      link.type = `text/css`;
      link.href = "/dist/flipdown.min.css";
      link.rel = "stylesheet";
      document.body.appendChild(link);
    }

    let _timer = setTimeout(() => {
      // Ensure timer string is in ISO 8601 format with UTC
      let _convertedDateTime = timer.replace(/ /g, "T") + "Z";
      var expireAt = new Date(_convertedDateTime);
      if (expireAt.toString() === "Invalid Date") {
        setTimerStatus("Invalid Date");
        return;
      }

      var now = new Date();
      var nowUTC = new Date(now.toUTCString());
      if (expireAt.getTime() > nowUTC.getTime()) {
        var twoDaysFromNow = expireAt.getTime() / 1000 + 1;
        var flipdown = new window["FlipDown"](twoDaysFromNow)
          .start()
          .ifEnded(() => {
            console.log("The countdown has ended!", flipdown);
            setTimerStatus("Closed");
          });
      } else {
        setTimerStatus("Closed");
      }
    }, 500);

    return () => {
      clearTimeout(_timer);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div
      css={style.club__timer__container}
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      {timerStatus === "" ? (
        <div id='flipdown' className='flipdown'></div>
      ) : (
        <StatusText text={timerStatus} />
      )}
    </div>
  );
}

export function StatusText({ text }) {
  return <div css={style.order__close__text}>{text}</div>;
}
