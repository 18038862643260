import React from "react";
import { VideoPanel } from "./view/videoPanel";
// import cmsUtils from '../cmsUtils';
// import { BannerPanel } from '../../../cms/items/bannerPanel/view';

export const renderRule = {
  match: (item, cmsOption) => item.itemTypeCode === "VideoPanel",
  //itemElt: (item, cmsOption) => <VideoPanel item={item} cmsOption={cmsOption} />
  itemElt: (item, cmsOption) => {
    //const useRBonMobile = cmsUtils.payload(item, 'UseRBonMobile') || 'no';

    // if(useRBonMobile === 'yes' && window.innerWidth <= 500){
    //   return <BannerPanel item={item} cmsOption={cmsOption} />
    // }
    // else{
    //   return <VideoPanel item={item} cmsOption={cmsOption} />
    // }

    return <VideoPanel item={item} cmsOption={cmsOption} />;
  },
};
