//import { css } from '@emotion/core'
import { mq } from 'cssInJs'

// const _width1 = "50px" ;
// const _height2 = "40px" ;
// const _height1 = "80px" ;
// const _lineHeight1 = "80px" ;
// const _fontSize = '84px';

const _width1s = "30px";
const _height2s = "20px";
const _height1s = "40px";
const _lineHeight1s = "40px";
const _fontSizes = '34px';

const _width1 = "40px";
const _height2 = "30px";
const _height1 = "60px";
const _lineHeight1 = "60px";
const _fontSize = '46px';

export default {

  club__timer__container: {

    marginTop: '1rem',
    marginBottom: '1rem',
    '.flipdown': {
      margin: 'auto',
      width: 'max-content',
      height: '72px',
      //backgroundColor: 'red',
      '.rotor-group-heading' : {
        fontSize : '15px',
        
      }
    },
    '.flipdown.flipdown__theme-dark .rotor-group-heading:before' : {
      color:'white'
    },
    '.flipdown .rotor': mq({
      width: [_width1s,null,_width1],
      height:[_height1s,null,_height1] ,
      fontSize:[_fontSizes,null,_fontSize]
    }),
    '.flipdown .rotor-leaf': mq({
      width:[_width1s,null,_width1],
      height:[_height1s,null,_height1]
    }),
    '.flipdown .rotor-leaf-rear': mq({
      width: [_width1s,null,_width1],
      height:[_height2s,null,_height2] 
    }),
    '.flipdown .rotor-leaf-front': mq({
      width: [_width1s,null,_width1] ,
      height: [_height2s,null,_height2],
      lineHeight:[_lineHeight1s,null,_lineHeight1] ,
      //backgroundColor: 'red !important'
    }),
    '.flipdown .rotor-top': mq({
      width: [_width1s,null,_width1] ,
      height: [_height2s,null,_height2],
      lineHeight:[_lineHeight1s,null,_lineHeight1]  
    }),
    '.flipdown .rotor-bottom': mq({
      width: [_width1s,null,_width1] ,
      height: [_height2s,null,_height2] 
    }),
    '.flipdown .rotor:after': mq({
      width: [_width1s,null,_width1] ,
      height: [_height2s,null,_height2] 
    }),
    '.flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):before': mq({
      left: ['76px',null,'95px'],
      bottom: ['5px',null,'15px'],
      backgroundColor: 'white !important',
      width: '5px',
      height: '5px'
    }),
    '.flipdown .rotor-group:nth-child(n+2):nth-child(-n+3):after' : mq({
      left : ['76px',null,'95px'],
      //top : '5px',
      bottom: ['24px',null,'40px'],
      backgroundColor: 'white !important',
      width: '5px',
      height: '5px'
    })
  },
  order__close__text: {
    textAlign: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
    fontWeight: '600',
    fontSize: '1.5rem'
  }
}


