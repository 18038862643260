const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  yellowColor : '#faa222',
  lightGrayColor : '#ebebeb',
  redColor : '#a3271a',
  darkGrayColor : '#252525'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"ballers-delight",serif',
  familyHeader2: '"roboto-condensed",serif',

  primaryColor: colors.yellowColor,
  fontColor: colors.lightGrayColor,
  bgColor: 'black',

  ...colors,
  ...overloading
}

export default variables;