import { mq } from '../../../cssInJs'

export default {
  // orderDetails: {
  //   textAlign: 'left',
  //   marginBottom: '3rem',
  //   paddingLeft: '1rem',
  //   paddingRight: '1rem',
  //   background: '#000'
  // },
  orderDetails: mq({
    textAlign: 'left',
    marginBottom: '3rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    background: '#000',
    paddingTop: '1rem',
    paddingBottom: ['19rem', '19rem', '19rem', '6rem']
  }),
  summary: {

  },

  fn: mq({
    minWidth: ['7rem', null, '10em'],
    margin: 0
  }),
  fv: {

  },

  orderItems: {},
  orderItem: {
    padding: '0.5rem 0',
    margin: '0.5rem 0',
    borderBottom: 'solid 1px #ccc'
  },
  total: {
    //marginLeft: 15,
    fontWeight: 'bold',
    color: 'chocolate'
  }
}
