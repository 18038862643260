import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useRouter } from './components';
import { StoreProvider } from './store';
import { ViewPage } from './cms/pages/viewPage/viewPage';
import { EditPage } from './cms/pages/editPage/editPage';
import { TopHeader } from './layout/topHeader/topHeader';
// import { Footer } from './layout/footer/footer';
import { BottomDock } from './layout/bottomDock/bottomDock';
// import { BlogView } from './site/blog/blogView';
import { AppVersionPage } from './components';
import { MediaQueryStatus } from './components';
import { CommonVideoPopup } from "./layout/popup/commonVideoPopup";
import { HelmetProvider } from 'react-helmet-async';
//import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { AppRouteChanged } from './AppRouteChanged';
import AOS from 'aos';

//For ECM
import { ProductView } from './site/ecm/product/productView';
import { OrderDetails } from './site/ecm/orderDetails/orderDetails';
import env from './env';
import { useStore } from './store';

function AppLayout(props) {
  const { pathname } = useRouter();
  
  //For ECM
  const storeContext = useRef(useStore());
  
  //const [scrolled, setScrolled] = useState(false)
  //const path = usePath();
  //const routeResult = useRoutes(routes);

  // const parallax = useParallax();
  // //console.log(path, routeResult);
  // const routeChanged = (path) => {
  //   //console.log(path);
  //   parallax.reset();
  // }

  // useScrollPosition(({ prevPos, currPos }) => {
  //   //console.log(currPos.x)
  //   //console.log(currPos.y);
  //   if (currPos.y > 90) {
  //     document.querySelector('body').classList.add('scrolled')
  //   } else {
  //     document.querySelector('body').classList.remove('scrolled')
  //   }
  //   //setScrolled(currPos.y > 90)
  // }, [], null, true)

  //const pageChanged = useCallback(path => routeChanged(path), [])
  //useLocationChange((path) => { routeChanged(path, history)})
  //useLocationChange(path => routeChanged(path))
  
  //const pathFiltered = (pathname || '').toLocaleLowerCase();
  let specialClassName = '';
//  if (pathFiltered.indexOf('/digital-sensor') >= 0) specialClassName = 'digital-sensor-page';
//  else if (pathFiltered === '/brilliance') specialClassName = 'brilliance-page';

useEffect(()=>{
  
  if(!localStorage.favorites){
    localStorage.setItem("favorites", "");
  }
  
  AOS.init({
    duration : 1200
    //delay:400,
    //once:true
  });

  //For ECM
  fetch(env.apiBase + "/api/cart/getcart/" + env.getDevCartId())
    .then(res => res.json())
    .then(res => {
        const data = res;
        if(data){
          storeContext.current.dispatch({type:'SET_STATE', payload : {cartInfo : data.cartInfo.totalQuantity === 0 ? 0 : (data.cartInfo.totalQuantity)-1}});
        }
    })
    .catch(error =>{
      storeContext.current.dispatch({type:'SET_STATE', payload : {cartInfo : 0}});
  });

}, []);

  return (
    <div className={`body-content ${pathname === '/' ? 'homepage' : 'subpage'} ${specialClassName}`}>
      <AppRouteChanged />
      <TopHeader></TopHeader>
      <div className="main-content">
        <div className="app-content">
          <Switch>
            <Route path="/ver" children={<AppVersionPage />} />
            <Route path="/cms/page/render/:id" children={<EditPage />} />
            {/* <Route path="/blog/:id" children={<BlogView />} /> */}
            
            <Route path="/admin">
              <Redirect to="/admin" />
            </Route>

            <Route path="/order-thankyou" children = {<OrderDetails />}/>
            <Route path="/product/:slug" children = {<ProductView />}/>

            <Route path="/">
              <ViewPage />
            </Route>
          </Switch>
        </div>
        {/* <app-common-bottom-partial></app-common-bottom-partial> */}
      </div>
      {/* <Footer></Footer> */}
      <BottomDock></BottomDock>
      <MediaQueryStatus />
      <CommonVideoPopup />
    </div>
  );
}

function App() {
  return (
    <StoreProvider>
      <HelmetProvider>
      <Router>
        <AppLayout />
      </Router>
      </HelmetProvider>
    </StoreProvider>
  );
}

export default App;